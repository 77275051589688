var config = {};
config.env = 'int2';

//AWS
config.aws = {};
config.aws.apiRoot = "https://int-api-gimsdev.amgen.com/";

//SSO
config.sso = {};
config.sso.url = "https://dev-630954.okta.com/home/selfdev630954_demookta_2/0oa1ihwzcwpC2mKfu357/aln1iiaocr7jdzWe1357";

//api-key
config.api = {};
config.api.key = 'pVEZoiihL27V7QA7sGHu74JDYGgKPxKju5sas2F1';

export default config;
